@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Comfortaa";
  src: url("../public/fonts/Comfortaa-VariableFont.ttf");
}
@font-face {
  font-family: "SpaceGrotesk";
  src: url("../public/fonts/SpaceGrotesk-VariableFont.ttf");
}

* {
  font-family: Comfortaa;
}
@keyframes bounce-custom {
  0%,
  100% {
    transform: translateY(-55%);
    animation-timing-function: cubic-bezier(0.8, 0, 1, 1);
  }
  50% {
    transform: translateY(0%);
    animation-timing-function: cubic-bezier(0, 0, 0.1, 1);
  }
  100% {
    transform: translateY(35%);
    animation-timing-function: cubic-bezier(0, 0, 0.1, 1);
  }
}
.animate-bounce-custom {
  animation: bounce-custom 1.5s infinite;
}

.rotate-x-custom {
  filter: grayscale(0.7);
}
.service-template-card:hover .rotate-x-custom {
  filter: grayscale(0);
}
.service-template-card:hover {
  box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.4);
  border: 1px solid rgba(0, 0, 0, 0.1);
  @apply font-medium;
}

.service-template-card {
  border-radius: 10px;
  transition: all 0.6s;
}
.service-template-card:hover .service-template-card-link {
  @apply text-rose-600;
}
.flip_card {
  perspective: 1000px;
  position: relative;
}
.flip_card_inner {
  transform-style: preserve-3d;
  transition: all 0.5s;
}
.flip_card_face {
}
.flip_card_back {
  transform: rotateY(180deg);
}
.flip_card_face,
.flip_card_back {
  position: absolute;
  backface-visibility: hidden;
  border-radius: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.flip_card:hover .flip_card_inner {
  transform: rotateY(180deg);
}

/* Track */
::-webkit-scrollbar {
  width: 6px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #2c1212;
  border-radius: 14px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

@keyframes jumpOnce {
  from {
    filter: opacity(0);
  }
  to {
    filter: opacity(1);
    
  }
  
}

.rotate-on-load {
  animation: jumpOnce 1s linear;
}
